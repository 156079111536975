<template>
  <v-container v-if="details !== null">
    <h3 class="secondary--text mt-5">Booking Details</h3>
    <hr class="hor">
    <v-card class="pa-5 mt-5">
      <v-row class="">
        <v-simple-table dense style="width: 100%;">
          <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">PNR</th>
                    <th class="text-left">Date</th>
                    <th class="text-left">Time</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Price</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ details.pnr_id }}</td>
                    <td>{{ formatDateTime(details.created_at.substr(0,10)) }}</td>
                    <td>{{ details.created_at.substr(11,20) }}</td>
                    <td>{{ details.status.status }}</td>
                    <td>${{ details.price }}</td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-card>

    <h3 class="secondary--text mt-5">Contact Person Details</h3>
    <hr class="hor">
    <v-card class="pa-5 mt-5">
      <v-row class="">
        <v-simple-table dense style="width: 100%;">
          <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Name</th>
                    <th class="text-left">Email</th>
                    <th class="text-left">Phone</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ details.contact_person }}</td>
                    <td>{{ details.contact_email }}</td>
                    <td>{{ details.contact_phone }}</td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-row>
    </v-card>

    <h3 class="secondary--text mt-5">Passengers Details</h3>
    <hr class="hor">
    <v-card class="pa-5 mt-5">
      <v-row class="">
        <v-simple-table dense style="width: 100%;">
            <template v-slot:default>
              <thead>
                  <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Birth Date</th>
                      <th class="text-left">Passport</th>
                      <th class="text-left">Passport Expire Date</th>
                      <th class="text-left">Issue Country</th>
                      <th class="text-left">Ticket</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="passenger in details.passenger_details" :key="passenger.id">
                      <td>{{ passenger.passenger_name }}</td>
                      <td>{{ formatDateTime(passenger.date_of_birth) }}</td>
                      <td>{{ passenger.passport_number }}</td>
                      <td>{{ formatDateTime(passenger.passport_expire_date) }}</td>
                      <td>{{ passenger.passport_issue_country }}</td>
                      <td>{{ passenger.ticket_number ? passenger.ticket_number : 'Not ticketed yet' }}</td>
                  </tr>
              </tbody>
            </template>
        </v-simple-table>
      </v-row>
    </v-card>

    <h3 class="secondary--text mt-5">Flight Segments</h3>
    <hr class="hor">
    <v-row class="" v-for="(segment, i) in details.flight_segments" :key="segment.id">
      <v-card class="px-5 ma-3 mt-5" width="100%">
        <h3 class="secondary--text my-2">Segment {{i + 1}}</h3>
        <v-simple-table dense style="width: 100%;">
          <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">Airline</th>
                    <th class="text-left">Flight NO.</th>
                    <th class="text-left">Duration</th>
                    <th class="text-left">From</th>
                    <th class="text-left">To</th>
                    <th class="text-left">Status</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ segment.airline }}</td>
                    <td>{{ segment.flight_number }}</td>
                    <td>{{ segment.flight_duration }}h</td>
                    <td>
                      {{ segment.departure_location }}
                      <br>
                      {{formatDateTime(segment.departure_date)}}, {{segment.departure_time}}
                    </td>
                    <td>
                      {{ segment.arrival_location }}
                      <br>
                      {{formatDateTime(segment.arrival_date)}}, {{segment.arrival_time}}
                    </td>
                    <td>
                      {{segment.status.name}}
                    </td>
                </tr>
            </tbody>
          </template>
        </v-simple-table>
        </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ['details'],
  data () {
    return {
    }
  },
  methods: {
    formatDateTime (date) {
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      return `${day} ${months[month - 1]} ${year}`
    }
  }
}
</script>

<style>
  .hor {
    border: none;
    height: 2px;
    width: 169px;
    background-color: #DF731C;
    margin: 15px 0;
  }
</style>
