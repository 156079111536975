<template>
  <div>
    <v-progress-linear
      indeterminate
      color="secondary"
      height="10"
      top
      :active="loaded"
    ></v-progress-linear>
    <v-snackbar :color="color" :timeout="3000" top v-model="alert">
      <v-row>
        {{ text }}
        <v-spacer></v-spacer>
        <v-icon color="white" @click="alert = false">mdi-close</v-icon>
      </v-row>
    </v-snackbar>

    <v-container fluid v-if="!loaded">
      <v-dialog
        v-model="pnrDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="secondary">
            <v-btn icon dark @click="pnrDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ details.pnr_id }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <pnr :details="details"></pnr>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="headline secondary white--text" primary-title>
            Update your profile
            <v-spacer></v-spacer>
            <v-btn icon color="white" @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-form ref="form" v-model="valid" v-on:submit.prevent>
            <v-card-text>
              <v-text-field
                label="Full Name"
                v-model="name"
                outlined
                clearable
                prepend-inner-icon="mdi-account"
                :rules="[(v) => !!v || 'Name is required']"
                color="secondary"
                id="profile-fullName"
              >
              </v-text-field>
              <phoneInput @update="assignPhone" :phone="user.phone" />
            </v-card-text>

            <v-card-actions>
              <v-btn
                color="secondary"
                tile
                block
                type="submit"
                :disabled="!valid"
                :loading="loading"
                @click="update"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-container>

    <v-container fluid>
      <v-row justify="center">
        <v-col cols="12" sm="7" md="4">
          <v-card outlined>
            <v-card-text>
              <div class="text-center">
                <v-avatar color="orange" size="64" class="text-center"
                  ><span class="white--text text-h5">{{
                    user.name.charAt(0)
                  }}</span></v-avatar
                >
              </div>
              <v-list>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-account</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-email</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-phone</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.phone }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-wallet-giftcard</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title> {{ user.points }} Points </v-list-item-title>
                    <v-list-item-subtitle class="mt-2"
                      >Your points are equal to <br />
                      <strong class="mt-1">{{
                        (user.points / 100) | changeCurrency
                      }}</strong>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn text color="orange" @click="dialog = true"
                ><v-icon left> mdi-pencil</v-icon> update</v-btn
              >
              <v-spacer></v-spacer>
              <!-- <v-btn text color="orange"><v-icon left> mdi-lock</v-icon> change password</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="8">
          <v-card outlined class="mb-5" v-if="user.referralCode">
            <v-card-title class="orange--text">Referral</v-card-title>
            <v-card-text>
              Your Rreferral code is: <strong>{{ user.referralCode }}</strong>
            </v-card-text>
          </v-card>
          <v-card outlined>
            <v-tabs v-model="activeTab" outlined color="orange">
              <v-tab> Flights Booking </v-tab>
              <v-tab> Hotels Booking </v-tab>
              <v-tab> Cars Booking </v-tab>
              <v-tab v-if="redeem.length > 0 && user.role === 'customer'">
                Redeemed Bookings
              </v-tab>
              <v-tab v-if="points.length > 0 && user.role === 'customer'">
                Points Collection
              </v-tab>
            </v-tabs>
            <v-divider></v-divider>
            <v-tabs-items v-model="activeTab">
              <!-- flights -->
              <v-tab-item class="pa-5 text-center">
                <p v-if="flightBookings.length === 0" class="display-1 font-weight-thin">
                  No flights bookings found
                </p>
                <v-data-table
                  v-if="flightBookings.length > 0"
                  :items="flightBookings"
                  :headers="headers"
                  :items-per-page="10"
                  class="mx-0 px-0"
                  :loading="tableLoad"
                >
                  <template v-slot:item.booking_reference="{ item }">
                    <!-- <span v-if="user.role === 'customer'">{{item.booking_reference}}</span> -->
                    <v-btn text color="blue" @click="showMail(item.booking_reference)">{{
                      item.booking_reference
                    }}</v-btn>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <span>{{ item.price | changeCurrency(item.price) }}</span>
                  </template>
                  <template v-slot:item.booking_date="{ item }">
                    <p v-if="item.booking_date" class="mb-0">
                      {{ getThisDate(item.booking_date.split(" ")[0]) }}
                    </p>
                    <span v-if="item.booking_date" class="caption grey--text darken-2">{{
                      item.booking_date.split(" ")[1]
                    }}</span>
                  </template>
                  <template v-slot:item.origin="{ item }">
                    <span v-if="item.departureAirport">{{
                      `(${item.departureAirport.code}) ${item.departureAirport.city},${item.departureAirport.country}`
                    }}</span>
                    <span v-else>{{ item.flight_segments[0].departure_location }}</span>
                    <p v-if="item.departureDate" class="body-2 grey--text darken-1">
                      {{ getThisDate(item.departureDate) }}
                    </p>
                    <p v-else class="body-2 grey--text darken-1">
                      {{ getThisDate(item.flight_segments[0].departure_date) }}
                    </p>
                  </template>
                  <template v-slot:item.dist="{ item }">
                    <span v-if="item.arrivalAirport">{{
                      `(${item.arrivalAirport.code}) ${item.arrivalAirport.city},${item.arrivalAirport.country}`
                    }}</span>
                    <span v-else>{{
                      item.flight_segments[item.flight_segments.length - 1]
                        .arrival_location
                    }}</span>
                    <p v-if="item.arrivalDate" class="body-2 grey--text darken-1">
                      {{ getThisDate(item.arrivalDate) }}
                    </p>
                    <p v-else class="body-2 grey--text darken-1">
                      {{
                        getThisDate(
                          item.flight_segments[item.flight_segments.length - 1]
                            .arrival_date
                        )
                      }}
                    </p>
                  </template>
                  <template v-slot:item.ticket_number="{ item }">
                    <p>
                      {{ item.ticket_number ? item.ticket_number : "Not ticketed yet" }}
                    </p>
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- hotels -->
              <v-tab-item class="pa-5 text-center">
                <p v-if="hotelsBookings.length === 0" class="display-1 font-weight-thin">
                  No hotels bookings found
                </p>
                <v-data-table
                  v-if="hotelsBookings.length > 0"
                  :items="hotelsBookings"
                  :items-per-page="10"
                  :headers="hotelHeaders"
                  class="elevation-1"
                >
                  <template v-slot:item.price="{ item }">
                    <span>{{ item.price | changeCurrency(item.price) }}</span>
                  </template>
                  <template v-slot:item.date="{ item }">
                    {{ getThisDate(item.booking_date.substr(0, 10)) }},
                    {{ item.booking_date.substr(11, 16) }}
                  </template>
                  <template v-slot:item.desc="{ item }">
                    {{ item.guests.length }} guests, {{ item.rooms.length }} rooms
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- cars -->
              <v-tab-item class="pa-5 text-center">
                <p v-if="carBookings.length === 0" class="display-1 font-weight-thin">
                  No Cars bookings found
                </p>
                <v-data-table
                  v-if="carBookings.length > 0"
                  :items="carBookings"
                  :items-per-page="10"
                  :headers="carsHeaders"
                  class="elevation-1"
                >
                  <template v-slot:item.price="{ item }">
                    <span>{{ item.price | changeCurrency(item.price) }}</span>
                  </template>
                  <template v-slot:item.booking_date="{ item }">
                    {{ getThisDate(item.booking_date.substr(0, 10)) }},
                    {{ item.booking_date.substr(11, 16) }}
                  </template>
                  <template v-slot:item.car="{ item }">
                    {{ item.car_details[0].car_type }}
                  </template>
                  <template v-slot:item.vendor="{ item }">
                    {{ item.car_details[0].vendor }}
                  </template>
                  <template v-slot:item.loc="{ item }">
                    {{ item.car_details[0].pickup_location }}
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- redeemed points -->
              <v-tab-item class="pa-5 text-center">
                <v-data-table
                  v-if="redeem.length > 0"
                  :items="redeem"
                  :headers="redeemHeaders"
                  :items-per-page="10"
                  class="elevation-1"
                >
                  <template v-slot:item.price="{ item }">
                    <span>{{ item.price | changeCurrency(item.price) }}</span>
                  </template>
                </v-data-table>
              </v-tab-item>

              <!-- points collection -->
              <v-tab-item class="pa-5 text-center">
                <v-data-table
                  v-if="points.length > 0"
                  :items="points"
                  :headers="pointsHeaders"
                  :items-per-page="10"
                  class="elevation-1"
                >
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  profile,
  cancelFlight,
  headers,
  userBookings,
  userUpdate,
  collectPoints,
  redeem,
  showPNR
} from '../links'
import pnr from '../components/showPnr.vue'
import phoneInput from '../components/mobile.vue'

export default {
  title () {
    return `-${this.$store.state.user.name || 'profile'}`
  },
  components: {
    pnr,
    phoneInput
  },
  data () {
    return {
      activeTab: 0,
      tabs: ['About', 'Posts', 'Friends'],
      user: {},
      tab: null,
      flightBookings: [],
      hotelsBookings: [],
      points: [],
      redeem: [],
      carBookings: [],
      headers: [
        {
          text: 'PNR',
          align: 'start',
          sortable: false,
          value: 'booking_reference',
          width: 120
        },
        { text: 'Price', value: 'price' },
        { text: 'Status', value: 'status' },
        { text: 'Booked on', value: 'booking_date' },
        { text: 'From', value: 'origin' },
        { text: 'To', value: 'dist' }
      ],
      hotelHeaders: [
        {
          text: 'Booking No.',
          align: 'start',
          sortable: false,
          value: 'booking_number'
        },
        { text: 'Hotel', value: 'hotel_name' },
        { text: 'Price', value: 'price' },
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Payment', value: 'payment_status' },
        { text: 'Status', value: 'booking_status' },
        { text: 'Date', value: 'date' },
        { text: 'Description', value: 'desc' }
      ],
      carsHeaders: [
        {
          text: 'Booking No.',
          align: 'start',
          sortable: false,
          value: 'booking_reference'
        },
        { text: 'Car', value: 'car' },
        { text: 'Vendor', value: 'vendor' },
        { text: 'Location', value: 'loc' },
        { text: 'Price', value: 'price' },
        { text: 'Payment Type', value: 'payment_type' },
        { text: 'Status', value: 'status' },
        { text: 'Date', value: 'booking_date' }
      ],
      redeemHeaders: [
        {
          text: 'Booking Reference.',
          align: 'start',
          sortable: false,
          value: 'booking_reference'
        },
        { text: 'Type', value: 'booking_type' },
        { text: 'Spent Points', value: 'points' },
        { text: 'Price', value: 'price_amount' },
        { text: 'Points per Dollar', value: 'points_per_unit_price' }
      ],
      pointsHeaders: [
        {
          text: 'Booking Reference.',
          align: 'start',
          sortable: false,
          value: 'booking_reference'
        },
        { text: 'Type', value: 'book_type' },
        { text: 'Earned Points', value: 'points' },
        { text: 'Description', value: 'description' },
        { text: 'Date', value: 'date' }
      ],
      dialog: false,
      pnrDialog: false,
      details: {},
      loading: false,
      loaded: true,
      valid: true,
      alert: false,
      color: '',
      text: '',
      name: '',
      phone: '',
      disableCancelBtn: false,
      tableLoad: false
    }
  },
  methods: {
    assignPhone (phone) {
      this.phone = phone
    },
    showMail (pnr) {
      this.$http
        .get(showPNR(pnr), { headers: headers(this.$cookies.get('userToken')) })
        .then(
          (response) => {
            if (response.body.status === true) {
              this.details = response.body.data
              this.pnrDialog = true
            } else {
              this.alert = true
              this.color = 'error'
              this.text = response.body.message
            }
          },
          () => {
            this.alert = true
            this.color = 'error'
            this.text = 'Something went wrong please try again in few seconds'
          }
        )
    },
    getThisDate (date) {
      const givenDate = date
      const [year, month, day] = givenDate.split('-')
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
      return `${day} ${months[month - 1]} ${year}`
    },
    openDialog () {
      this.dialog = true
    },
    update () {
      this.loading = true
      this.$refs.form.validate()
      if (this.valid) {
        if (this.phone.valid) {
          const body = {
            email: this.user.email,
            name: this.name,
            phone: this.phone.nationalNumber,
            countryIsoCode: this.phone.countryCode
          }
          this.$http
            .post(userUpdate, body, { headers: headers(this.$cookies.get('userToken')) })
            .then((response) => {
              if (response.body.status === false) {
                this.alert = true
                this.color = 'error'
                this.text = response.body.message
                this.loading = false
                this.dialog = false
              } else {
                this.alert = true
                this.color = 'success'
                this.text = 'Your profile has been updated successfully'
                this.loading = false
                this.dialog = false
                this.$store.dispatch('setUser', this.user)
                this.user.name = this.name
                this.user.phone = this.phone.formatted
              }
            })
        } else {
          this.alert = true
          this.color = 'error'
          this.text = 'Please provide a valid mobile number'
          this.loading = false
          this.dialog = false
        }
      }
    },
    cancelFlight (flight) {
      this.disableCancelBtn = true
      this.tableLoad = true
      this.$http
        .post(
          cancelFlight,
          { pnr: flight.booking_reference },
          { headers: headers(this.$cookies.get('userToken')) }
        )
        .then(
          (response) => {
            this.disableCancelBtn = false
            this.tableLoad = false
            if (response.body.status) {
              this.alert = true
              this.color = 'success'
              this.text = 'Flight cancelled'
              this.flightBookings.forEach((item) => {
                if (item.booking_reference === flight.booking_reference) {
                  item.status = 'Cancelled'
                }
              })
            } else {
              this.alert = true
              this.color = 'error'
              this.text = response.body.message
            }
          },
          (err) => {
            this.disableCancelBtn = false
            this.alert = true
            this.color = 'error'
            this.text = err.body.message
          }
        )
    }
  },
  beforeMount () {
    if (!this.$cookies.isKey('userToken')) {
      this.$router.push({ name: 'login' })
    } else {
      this.$http
        .get(profile, { headers: headers(this.$cookies.get('userToken')) })
        .then((response) => {
          this.user = response.body.data
          this.name = response.body.data.name
          this.phone = response.body.data.phone
          this.loaded = false
        })
      this.$http
        .get(userBookings, { headers: headers(this.$cookies.get('userToken')) })
        .then((response) => {
          if (response.body.data.FlightsBookings) {
            this.flightBookings = response.body.data.FlightsBookings
          }
          this.headers.push({ text: 'Payment Type', value: 'payment_type' })
          if (response.body.data.HotelsBookings) {
            this.hotelsBookings = response.body.data.HotelsBookings
          }
          if (response.body.data.CarBookings) {
            this.carBookings = response.body.data.CarBookings
          }
        })
      if (this.$store.state.user.role === 'customer') {
        this.$http
          .get(redeem, { headers: headers(this.$cookies.get('userToken')) })
          .then((response) => {
            this.redeem = response.body.data
          })
        this.$http
          .get(collectPoints, { headers: headers(this.$cookies.get('userToken')) })
          .then((response) => {
            this.points = response.body.data
          })
      }
    }
  }
}
</script>

<style>
[v-cloak] {
  display: none;
}
</style>
